body {
    /* Fixed Body for fixed navigation bar at top */
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overscroll-behavior-y: contain;

    /* Disable Text Selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

Link, a {
    text-decoration: none
}

.overflow {
    overflow: scroll;
}

.appLoader {
    z-index: 10;
    position: absolute;
}

.content {
    /* Content of app besides Navigation Bar */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 50px;
}

.footer {
    position: absolute;
    bottom: 0;
    z-index: 1;
    left: 0;
    width: 100%;
}
.spaceBlocker {
    height: 120px
}

.spaceBlockerRTO {
    height: 90px
}

.spaceBlockerMedium {
    height: 60px
}

.toast {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 50px;
    margin-left: 15%;
}

.bottomSticky {
    position: fixed;
    bottom: 0;
    width: 100%
}

.scrollList {
    overflow-y: scroll;
    height: 80vh;
}